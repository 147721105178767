<template>
    <div>
        <span class="tag" :class="istype==true? item.value === value? 'active' :'':(item.label=='工业场地' || item.label=='工业厂房')?'hides': item.value === value? 'active' :''" v-for="item in list" :key="item.value"
            @click="change(item)">{{item.label}} </span>
    </div>
</template>

<script>
export default {
    props: {
      istype:{
        default: () => false
      },
        list: {
            type: Array,
            default: () => []
        },
        value: {
            default: ''
        },
    },
    model: {
        prop: 'value',
        event: 'changeValue'
    },
    data() {
        return {
            currentValue: ''
        }
    },
    methods: {
        change(item) {
            //this.currentValue = item.value;
            this.$emit('changeValue', item.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.tag {
    padding: 5px 15px;
    margin-right: 10px;
    color: #6e6e6e;
    font-size: 12px;
    cursor: pointer;
}

.active {
    color: #409eff;
}
.hides{
  display: none;
}
</style>

